html,
body {
  margin: 0;
  font-family: Poppins, Montserrat, sans-serif;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: auto;
  background-color: var(--bg);
}

html,
body,
#root {
  height: 100%;
  width: 100%;
  position: fixed;
}

button,
input,
textarea,
select {
  font-family: Poppins, Montserrat, sans-serif;
  font-weight: 400;
  color: #000;
}

:root {
  /* COLORS */
  --bg: #edf0f2;
  --accent-light-color: #076031;
  --accent-light-color-disabled: #a5d6a7;
  --accent-light-color-darken: #003509;

  --black-color: #343434;

  --background-grey: #999999;

  /* Z INDEX */
  --z-arrows: 0;
  --z-underlay: 4;
  --z-editor: 5;
  --z-overlay: 6;
  --z-toolbar: 10;
  --z-toolbar-backdrop: 11;
  --z-toolbar-active-item: 12;
  --z-toolbar-active-panel: 12;
  --z-switch-3d: 18;
  --z-navbar: 20;
  --z-action-bar: 21;
  --z-color-picker-container: 23;
  --z-color-picker: 24;

  /* TOOLBAR */
  --toolbar-width: 87px;
  --toolbar-bg: #f5f5f5;
  --toolbar-view-width: 265px;
  --delimiter-color: hsl(0, 0%, 89%);
  /* TOOLBAR'S CHECK */
  --check-circle-size: 20px;
  --check-offset: 5px;
  --check-right: var(--check-offset);
  --check-bottom: var(--check-offset);
  --check-scale: 0.5;
  --check-width: calc((var(--check-circle-size) / 2) * var(--check-scale));
  --check-height: calc(var(--check-circle-size) * var(--check-scale));
  --check-thickness: 3px;

  /* NAVBAR */
  --navbar-height: 70px;
  --navbar-padding-horizontal: 20px;
  --navbar-bg: rgb(40, 33, 84);
  --navbar-active-color: #201a44;

  /* FOOTER */
  --footer-height: 60px;
  --footer-bg: #f5f5f5;

  /* --navbar-padding-horizontal: 20px;
  --navbar-active-color: #8a58f4; */
  --photo-import-shift: calc(var(--vh, 1vh) * 6);
  --mobile-zoom-slider-color: var(--accent-light-color);
}

/* Global classes */
.text-center {
  text-align: center;
}

.no-selection {
  -webkit-user-select: none;
  /* webkit (safari, chrome) browsers */
  -moz-user-select: none;
  /* mozilla browsers */
  -khtml-user-select: none;
  /* webkit (konqueror) browsers */
  -ms-user-select: none;
  /* IE10+ */
}

.disabled-cursor {
  cursor: not-allowed !important;
}

* {
  box-sizing: border-box;
}

.loading-photo-wrapper ._loading_overlay_overlay {
  background: #f8f8f869 !important;
  z-index: auto;
}

.loading-photo-wrapper {
  position: absolute !important;
}

.loading-photo-wrapper ._loading_overlay_overlay > div > div {
  margin-bottom: 0;
}

.loading-photo-wrapper ._loading_overlay_overlay > div {
  text-shadow: -1px -1px 3px #000, 1px 1px 3px #000;
  width: calc(var(--loading-spinner-width) / 2);
}

/* CHECK */
.checked::before {
  z-index: 1;
  content: '';
  /* content: '✔'; */
  /* color: white; */
  /* text-align: center; */
  /* line-height: 35px; */
  position: absolute;
  bottom: var(--check-bottom);
  right: var(--check-right);
  width: var(--check-circle-size);
  height: var(--check-circle-size);
  border-radius: 50%;
  background-color: var(--accent-light-color);
}

.checked::after {
  --check-marging-right-left1: calc(var(--check-circle-size) - var(--check-width));
  --check-marging-right-left2: calc(var(--check-marging-right-left1) / 2);
  --check-marging-right-right: calc(var(--check-thickness) / 2);
  --check-margin-right: calc(var(--check-marging-right-left2) - var(--check-marging-right-right));
  z-index: 2;
  content: '';
  position: absolute;
  bottom: var(--check-bottom);
  right: var(--check-right);
  width: var(--check-width);
  height: var(--check-height);
  margin-right: var(--check-margin-right);
  margin-bottom: calc(((var(--check-circle-size) - var(--check-height)) / 2));
  border-right: var(--check-thickness) solid #fff;
  border-bottom: var(--check-thickness) solid #fff;
  transform: rotate(45deg);
}

/* CHECK */

.clearfix:after {
  content: '';
  display: table;
  clear: both;
}

/* 
._loading_overlay_wrapper {
  width: 100%;
  height: 100%;
  display: flex;
}
.mobile ._loading_overlay_wrapper {
  display: block;
}
._loading_overlay_wrapper.loading > *:not(._loading_overlay_overlay) {
  display: none;
}
._loading_overlay_wrapper > ._loading_overlay_overlay {
  background: white;
}
.mobile .loading-wrapper._loading_overlay_wrapper > ._loading_overlay_overlay {
  top: var(--navbar-height);
  height: calc(100% - var(--navbar-height));
}
._loading_overlay_wrapper
  > ._loading_overlay_overlay
  ._loading_overlay_content {
  filter: invert();
} */

.react-file-reader-input {
  left: -100%;
}

.textWrapper {
  line-height: inherit !important;
  font-size: inherit !important;
  word-break: break-word;
  outline: none;
}

.textWrapper * {
  line-height: inherit !important;
  font-size: inherit !important;
  word-break: break-word;
}

.cursorText {
  cursor: text;
}

p {
  margin: 0px;
}

.cursor-pointer {
  cursor: pointer;
}
